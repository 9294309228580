<template>
  <div>

    <CWidgetAnnouncementBar>
      Book & Sail %:&nbsp;<NuxtLink href="/kreuzfahrten" class="text-mongoose-300">Kreuzfahrt-Deals</NuxtLink>
    </CWidgetAnnouncementBar>

    <CPageWrapper>
      <template #header>
        <section class="relative">
          <CPageHeader invert-logo icon-col="text-white" class="pt-6 z-50 absolute inset-x-0 top-0 text-white bg-gradient-to-b from-indigo-900 to-transparent" />
          <div class="relative z-0">

            <slot name="header" />

          </div>
        </section>
      </template>

      <div class="flex flex-col gap-y-small sm:gap-y-medium">
        <CPartnerAmadeus :params="query" ibe="package" class="mt-10 sm:mt-16" />
        <slot name="content" />
      </div>

      <slot name="footer">
        <CPageContentSection>
          <CInfoline class="mb-4" no-spacing>Nützliche Tipps</CInfoline>
          <h2 class="mb-10 font-sansScript text-3xl sm:text-4xl text-center text-lux-700">Pauschalreisen - Einfach ein gutes Gefühl</h2>
          <CFlowCols no-spacing>
            <CInfoItem title="Wie kann ich meine Flugzeit verkürzen?">
              <p>Für mehr Komfort wähle Direktflüge.</p>
              <p>Mehr Reisetipps unter: <NuxtLink href="/service/haeufige-fragen" class="font-bold" aria-label="Häufig gestellte Fragen (FAQ)">Häufig gestellte Fragen (FAQ)</NuxtLink>
              </p>
            </CInfoItem>
            <CInfoItem title="Wie bekomme ich die besten Hotels angezeigt?">
              <p>Für die besten Hotels erhöhe die Anzahl der Sterne unter Hotelkategorie und du kannst noch zusätzlich Hotelbewertungen mit einbeziehen.</p>
            </CInfoItem>
            <CInfoItem title="Bist du flexibel bei deinen Reisedaten?">
              <p>Wenn du flexibel bist, kannst du oft bessere Flüge und Unterkünfte finden.</p>
            </CInfoItem>
            <CInfoItem title="Benötigst du eine Reiserücktrittsversicherung?">
              <p>Eine Reiserücktrittsversicherung schützt dich vor finanziellen Verlusten, wenn du deine Reise stornieren musst. Wir bieten dir eine große Auswahl an Reiseversicherungen, so dass du die für dich passende Versicherung finden kannst. Wir beraten dich gerne und beantworten all deine Fragen.</p>
              <p>
                <NuxtLink class="font-bold" href="/service/reiseversicherungen/reiseschutz-von-ergo" aria-label="Reiseversicherungen">Reiseversicherungen entdecken</NuxtLink>
              </p>
            </CInfoItem>
          </CFlowCols>
        </CPageContentSection>
      </slot>

    </CPageWrapper>
  </div>

</template>

<script setup lang="ts">
import pauschalJson from '~/assets/json/pauschal.json'
import specialsJson from '~/assets/json/specials.json'
import hotelOfferJson from '~/assets/json/hotel.offers.json'

interface ParamsType {
  key: string,
  name: string,
  query: string,
}

const route = useRoute()
const metaProps = route.meta
const pathType = metaProps.pathType as string
const paramSets: Record<string, ParamsType[]> = {
  'pauschal': pauschalJson,
  'hotels': hotelOfferJson,
  'specials': specialsJson,
}

const pathToMatch = route.path.endsWith('/') ? route.path.substring(0, route.path.length - 1) : route.path
const params: ParamsType = (paramSets[pathType]).filter((x: ParamsType) => x.key === pathToMatch)[0]
const query = metaProps.query ? metaProps.query as string : params.query

// const titleText = query.includes('aid') ? 'Die besten Angebote' : 'Die beliebtesten Hotels'
// const metaTitle = `Urlaub ${params.name}`.length < 50 ? `Urlaub ${params.name} buchen • ${company.name}` : `Urlaub ${params.name}`
// const metaTitle = `Urlaub ${params.name}`.length < 50 ? `Urlaub ${params.name} buchen • ${company.name}` : `Urlaub ${params.name}`
const metaTitle = `Urlaub ${params.name}`

const seoDescription = [
  "Bereit für dein nächstes Abenteuer?" +
  params.name + "Angebote✅" +
  params.name + "Last Minute✅" +
  params.name + "Urlaub✅" +
  params.name + "All-Inclusive✅"
].join(" ")

useSeoMeta({
  title: metaTitle,
  description: seoDescription,
  robots: { index: false, follow: false, noarchive: true, nositelinkssearchbox: true, nosnippet: true, indexifembedded: false, noimageindex: true },
})
</script>
